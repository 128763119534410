
import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {XTableProxy} from '@/components/x-table/table.proxy';
import XDialog from '@/components/x-dialog/x-dialog.vue';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';
import {ElTable} from 'element-ui/types/table';

@Component({
  name: 'x-table',
  components: {XDialog},
})
export default class XTable extends Vue {
  @PropSync('proxy', {required: true}) tableProxy!: XTableProxy;
  @PropSync('selectable', {
    default: () => true,
    type: Boolean
  }) isSelectable!: boolean;
  @PropSync('selectableAll', {
    default: () => true,
    type: Boolean
  }) isSelectableAll!: boolean;
  @PropSync('searchable', {
    default: () => true,
    type: Boolean
  }) isSearchable!: boolean;
  @PropSync('searchMore', {
    default: () => false,
    type: Boolean
  }) isSearchMore!: boolean;
  @PropSync('autoHeight', {
    default: () => false,
    type: Boolean
  }) isAutoHeight!: boolean;
  @PropSync('search', {
    default: () => true,
    type: Boolean
  }) isSearch!: boolean;

  tableHeight = 0;
  isSearching = false;
  searchFormRef: Form = null!;
  latestDataLength = 0;
  isLoading = false;
  searchDialog = $xDialog.create({
    title: '高级搜索',
    wrapperClass: 'search-dialog-container',
    beforeConfirm: () => this.handleSearch(),
  });

  mounted() {
    setTimeout(() => {
      this.searchFormRef = this.$refs.searchForm as any;
    });
  }

  @Watch('tableProxy', {immediate: true})
  proxyChange(proxy: XTableProxy) {
    const getElTable = () => this.$refs.tableInstance as any as ElTable;

    proxy.refreshPage = () => {
      const table = getElTable();
      if (!table) return;

      table.doLayout();

      const selectedData = this.tableProxy.data.filter(item => {
        table.toggleRowSelection(item, !!item.$selected);
        return item.$selected;
      });
      this.latestDataLength = selectedData.length;
      proxy._loading = this.isLoading = false;
    };

    proxy.rebuildLayout = () => {
      const table = getElTable();
      if (!table) return;

      table.doLayout();
    };

    proxy.loading = () => {
      proxy._loading = this.isLoading = true;
    };

    proxy.clearSort = () => {
      const table = getElTable();
      if (!table) return;
      table.clearSort();
    };
  }

  @Watch('$el.clientHeight', {immediate: true})
  calcTableHeight() {
    this.$nextTick(() => {
      if (!this.$el || this.isAutoHeight) return;
      let parentEl = this.$el.parentElement as HTMLDivElement;

      if (
        parentEl.classList.contains('el-scrollbar__view') ||
        parentEl.parentElement!.classList.contains('el-scrollbar__view')
      ) {
        parentEl = parentEl.parentElement as any;

        if (parentEl.parentElement!.classList.contains('app-main-scroll-wrap')) {
          parentEl = parentEl.parentElement as any;
        }
      }

      const parentElStyle = getComputedStyle(parentEl);

      const otherFormBtn = this.$el.querySelector('.other-form-btn')! || {clientHeight: 0};

      const headerEl = this.$el.querySelector('.x-table-header-wrapper')! || {clientHeight: 0};
      const footerEl = this.$el.querySelector('.x-table-footer')! || {clientHeight: 0};

      let height = parseInt(parentElStyle.height) -
        parseInt(parentElStyle.paddingTop) -
        parseInt(parentElStyle.paddingBottom);

      const minHeight = document.body.clientHeight / 2;

      if (height < minHeight) {
        height = minHeight;
      }
      let otherFormBtn_height = 0
      if (otherFormBtn.clientHeight !== 0) {
        otherFormBtn_height = otherFormBtn.clientHeight + 16
      }
      this.tableHeight = height -( Number(headerEl.clientHeight) >75?75:headerEl.clientHeight )- footerEl.clientHeight - otherFormBtn_height - 6;
    });
  }

  handleSizeChange() {
    this.tableProxy.pageIndex = 1;
    this.request();
  }

  handleCurrentChange() {
    this.request();
  }

  openSearch() {
    this.isSearching = true;
  }

  closeSearch() {
    this.isSearching = false;
  }

  toggleSearch() {
    this.isSearching = !this.isSearching;
  }

  handleSearch() {
    this.searchFormRef.validate((isValid: boolean, invalidFields) => {
      this.tableProxy.pageIndex = 1;
      if (isValid) {
        this.request();
      }
      this.tableProxy.select.selectedData = [];
      this.tableProxy.select.selectedDataKeyMap = [];
    });
    return false;
  }

  handleSearchReset() {
    this.$emit('reset', true);
    this.searchFormRef.resetFields();
    this.tableProxy.search.reset();
    this.tableProxy.select.reset();
    this.tableProxy.clearSort!();
    this.tableProxy.pageIndex = 1;
    this.request();
  }

  handleSelect(selection, row) {
    console.log(this.tableProxy.select.isAllSelectedMode)
    
    if(this.tableProxy.select.isAllSelectedMode === true) {
      this.tableProxy.select.selectedData = [];
      this.tableProxy.select.selectedDataKeyMap = [];
      this.tableProxy.select.isAllSelectedMode = false
    }
    console.log(selection, this.latestDataLength, row)
    row.$selected = !row.$selected
    // row.$selected = selection.length > this.latestDataLength;
    this.tableProxy.select.selectRow(row);
    this.latestDataLength = selection.length;
    // this.tableProxy.select.toggleAllSelect(1)
  }

  handleSelectAll() {
    if(this.tableProxy.select.isAllSelectedMode === true) {
      this.tableProxy.select.isAllSelectedMode = false
    }
    const isSelectionLengthMoreThan0 = this.tableProxy.data.filter(item => !item.$unselectable && item.$selected).length > 0;

    this.tableProxy.data.forEach(item => {
      if (item.$unselectable) return;
      item.$selected = !isSelectionLengthMoreThan0;
      this.tableProxy.select.selectRow(item);
    });

    this.tableProxy.refreshPage!();
  }

  handleSortChange(
    {
      column,
      prop,
      order
    }
  ) {
    if (!prop) return;
    const {search} = this.tableProxy;
    search.onSortChange(column, prop, order);
    this.request();
  }

  request() {
    this.tableProxy.request();
    this.closeSearch();
  }
}
